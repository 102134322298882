import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Form, Formik } from "formik";
import { useEffectOnce } from "react-use";
import {
  deleteClassWalletSession,
  getClassWalletSession,
} from "~/utils/classWalletSession";
import SubmitButton from "~/components/forms/SubmitButton";
import FormStatusError from "~/components/forms/FormStatusError";
import useFormikStatusSubmit from "~/hooks/useFormikStatusSubmit";
import { useApiBaseUrl, useApiClient } from "~/hooks/apiContext";
import { useAuthState } from "~/hooks/authContext";
import { useClassWalletVendorId } from "~/hooks/classWalletContext";
import useApiQuery from "~/hooks/useApiQuery";
import ErrorMessages from "~/components/ErrorMessages";
import ClassWalletStudentForm from "./ClassWalletStudentForm";
import classWalletIcon from "./class-wallet-icon.png";
import {
  ClassWalletSignUpFormValues,
  classWalletSignUpValidationSchema,
} from "./form";
import ClassWalletAuthForm from "./ClassWalletAuthForm";
import "~/features/marketing/learn-more.css";
import "./classWalletSignUp.css";

function ClassWalletSignUpPage() {
  const [session] = useState(getClassWalletSession);
  useEffect(() => {
    if (session === undefined && typeof window !== "undefined") {
      navigate("/get-started/homes-learn-more/");
    }
  }, [session]);

  const apiClient = useApiClient();
  const authState = useAuthState();
  useEffectOnce(() => {
    if (authState.type === "signedIn") {
      authState.signOut();
    }
  });

  // Prices
  const {
    isLoading: pLoading,
    error: pError,
    data: prices,
  } = useApiQuery(["accessPrices"], (a) => a.accessPrices(), {
    staleTime: Infinity,
  });
  const price = prices?.[0];

  // Submit
  const classWalletVendorId = useClassWalletVendorId();
  const apiBaseUrl = useApiBaseUrl();
  const onSubmit = useFormikStatusSubmit(
    async ({
      userAuthType,
      password,
      student,
    }: ClassWalletSignUpFormValues) => {
      if (!session) {
        throw new Error("No ClassWallet session");
      }
      if (!price) {
        throw new Error("No price yet");
      }

      await apiClient.authAndPopulateClassWalletSessionItems({
        orderId: session.orderId,
        userAuthType,
        password,
        student: {
          firstName: student.nickname,
          monthOfBirth: student.birthdayMonth.monthName,
          yearOfBirth: student.birthdayMonth.year.toString(),
          priceLookupKey: price.lookupKey,
          password: student.password,
          username: `${student.userNameName}@${student.userNameMathematician}`,
        },
      });

      const callbackUrl = new URL(
        apiBaseUrl + `/class-wallet/order-sessions/${session.orderId}`,
      );

      const checkoutUrl = new URL(
        "https://app.classwallet.com/payby-checkout/",
      );
      checkoutUrl.searchParams.set("callback", callbackUrl.toString());
      checkoutUrl.searchParams.set("vendorId", classWalletVendorId);

      deleteClassWalletSession();

      window.location.href = checkoutUrl.toString();
      return;
    },
  );

  // TODO: Show total price on checkout button
  const [isUsernameVerified, setUsernameVerified] = useState(false);

  if (session === undefined) {
    return null;
  }

  return (
    <div className="Site-inner Site-inner--index">
      <div className="Content-outer">
        <section className="get-started-headline">
          <div className="container class-wallet-sign-up">
            <h2>ClassWallet customers</h2>
            <div>
              To keep track of your student you will need to sign up for a
              parent account. Or if you already have a parent account you can
              sign in.
            </div>
            <Formik
              onSubmit={onSubmit}
              initialValues={
                {
                  userAuthType: undefined,
                  password: "",
                  confirmPassword: "",
                  agreeToTerms: false,
                  student: {},
                } as ClassWalletSignUpFormValues
              }
              validationSchema={classWalletSignUpValidationSchema}
            >
              <Form className="class-wallet-sign-up-forms-container">
                <div className="class-wallet-sign-up-forms">
                  <ClassWalletAuthForm
                    className="class-wallet-sign-up-form"
                    emailAddress={session.email}
                  />
                  <ClassWalletStudentForm
                    className="class-wallet-sign-up-form"
                    onUsernameVerifiedChange={setUsernameVerified}
                  />
                </div>
                <ErrorMessages errors={[pError]} />
                <FormStatusError />
                <div className="class-wallet-submit-row">
                  <SubmitButton
                    label="Checkout with ClassWallet"
                    disabled={pLoading || !isUsernameVerified}
                  />
                  <img
                    src={classWalletIcon}
                    alt="ClassWallet"
                    width={60}
                    height={60}
                  />
                </div>
              </Form>
            </Formik>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ClassWalletSignUpPage;
