import React from "react";
import { useFormikContext } from "formik";
import StudentFormFields from "~/features/dashboard/StudentFormFields";
import { ClassWalletSignUpFormValues } from "./form";

type ClassWalletStudentFormProps = {
  readonly className?: string;
  readonly onUsernameVerifiedChange: (newChange: boolean) => void;
};

function ClassWalletStudentForm({
  className,
  onUsernameVerifiedChange,
}: ClassWalletStudentFormProps) {
  const formikBag = useFormikContext<ClassWalletSignUpFormValues>();

  return (
    <>
      <div className={className}>
        <h3>Create student login</h3>
        <StudentFormFields
          formikBag={formikBag}
          fieldNamePrefix="student."
          onUsernameVerifiedChange={onUsernameVerifiedChange}
        />
      </div>
    </>
  );
}

export default ClassWalletStudentForm;
