import React from "react";
import TextFormItem from "~/components/forms/TextFormItem";
import StaticTextFormItem from "~/components/forms/StaticTextFormItem";

type ClassWalletSignInFormProps = {
  readonly emailAddress: string;
};

function ClassWalletSignInForm({ emailAddress }: ClassWalletSignInFormProps) {
  return (
    <>
      <h4>Sign in</h4>
      <StaticTextFormItem
        name="signInEmailAddress"
        label="Email address"
        value={emailAddress}
      />
      <TextFormItem
        type="password"
        name="password"
        label="Password"
        autoComplete="off"
      />
    </>
  );
}

export default ClassWalletSignInForm;
