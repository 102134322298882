import React from "react";
import CheckboxFormItem from "~/components/forms/CheckboxFormItem";
import TextFormItem from "~/components/forms/TextFormItem";
import StaticTextFormItem from "~/components/forms/StaticTextFormItem";

type ClassWalletSignUpFormProps = {
  readonly emailAddress: string;
};

function ClassWalletSignUpForm({ emailAddress }: ClassWalletSignUpFormProps) {
  return (
    <>
      <h4>Sign up</h4>
      <em>
        This account will be created only after the checkout process is
        complete.
      </em>
      <StaticTextFormItem
        name="emailAddress"
        label="Email address"
        value={emailAddress}
      />
      <TextFormItem
        type="password"
        name="password"
        label="Password"
        autoComplete="off"
      />
      <TextFormItem
        type="password"
        name="confirmPassword"
        label="Confirm password"
        autoComplete="off"
      />
      <CheckboxFormItem
        name="agreeToTerms"
        label={
          <>
            I have read the{" "}
            <a href="/privacy-policy/" target="_blank" rel="noopener noreferer">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href="/terms-of-use/" target="_blank" rel="noopener noreferer">
              Terms of Use
            </a>
            .
          </>
        }
      />
    </>
  );
}

export default ClassWalletSignUpForm;
