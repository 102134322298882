import React, { useEffect, useRef } from "react";
import { useFormikContext } from "formik";
import useApiQuery from "~/hooks/useApiQuery";
import ErrorMessages from "~/components/ErrorMessages";
import LoadingSpinner from "~/components/LoadingSpinner";
import ClassWalletSignUpForm from "./ClassWalletSignUpForm";
import ClassWalletSignInForm from "./ClassWalletSignInForm";
import { ClassWalletSignUpFormValues } from "./form";

type ClassWalletAuthFormProps = {
  readonly className?: string;
  readonly emailAddress: string;
};

function ClassWalletAuthForm({
  className,
  emailAddress,
}: ClassWalletAuthFormProps) {
  const { values, setFieldValue } =
    useFormikContext<ClassWalletSignUpFormValues>();
  const setFieldValueRef = useRef(setFieldValue);
  setFieldValueRef.current = setFieldValue;
  const { userAuthType } = values;

  const { data, error, isLoading } = useApiQuery(
    ["exists", emailAddress],
    (a) => a.exists(emailAddress),
  );
  useEffect(() => {
    if (data === undefined) {
      return;
    }

    setFieldValueRef.current("userAuthType", data ? "sign-in" : "sign-up");
  }, [data, setFieldValueRef]);

  return (
    <div className={className}>
      <h3>Parent Account</h3>
      <ErrorMessages errors={[error]} />
      {isLoading && (
        <>
          <LoadingSpinner /> <span>Checking email...</span>
        </>
      )}
      {userAuthType === "sign-up" && (
        <ClassWalletSignUpForm emailAddress={emailAddress} />
      )}
      {userAuthType === "sign-in" && (
        <ClassWalletSignInForm emailAddress={emailAddress} />
      )}
    </div>
  );
}

export default ClassWalletAuthForm;
