import React, { useState } from "react";
import Seo from "~/components/Seo";
import GeneralSignUpPage from "~/features/auth/GeneralSignUpPage";
import ClassWalletSignUpPage from "~/features/marketing/classWalletSignUp/ClassWalletSignUpPage";
import { getClassWalletSession } from "~/utils/classWalletSession";

function SignUp() {
  const [classWalletSession] = useState(getClassWalletSession);

  if (classWalletSession !== undefined) {
    return <ClassWalletSignUpPage />;
  }

  return <GeneralSignUpPage />;
}

export function Head() {
  return <Seo title="Sign Up" />;
}

export default SignUp;
