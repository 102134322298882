import * as yup from "yup";
import { newStudentValidationSchema } from "~/features/dashboard/StudentForm";
import { SchemaFieldValues } from "~/utils/SchemaFieldValues";

const classWalletSignUpValidationSchema = yup.object({
  userAuthType: yup
    .string()
    .oneOf(["sign-up", "sign-in"])
    .required() as yup.BaseSchema<"sign-up" | "sign-in">,
  password: yup.string().required(),

  // Sign up only
  confirmPassword: yup.string().when(["userAuthType", "password"], {
    is: (userAuthType: string, password: string) =>
      userAuthType === "sign-up" && !!(password && password.length > 0),
    then: yup
      .string()
      .required()
      .oneOf([yup.ref("password")], "Password must match"),
  }),
  agreeToTerms: yup.bool().when("userAuthType", {
    is: (v: string) => v === "sign-up",
    then: yup.bool().oneOf([true], "Required").required(),
  }),

  student: newStudentValidationSchema,
});

type ClassWalletSignUpFormValues = SchemaFieldValues<
  typeof classWalletSignUpValidationSchema
>;

export type { ClassWalletSignUpFormValues };
export { classWalletSignUpValidationSchema };
