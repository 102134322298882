import React from "react";
import FormItem, { FormItemProps } from "./FormItem";

type StaticTextFormItemProps = Pick<
  FormItemProps,
  "label" | "name" | "style"
> & {
  readonly value: string;
};

function StaticTextFormItem({
  name,
  style,
  label,
  value,
}: StaticTextFormItemProps) {
  const fieldId = `${name}Field`;

  return (
    <FormItem name={name} label={label} fieldId={fieldId} style={style}>
      <input
        id={fieldId}
        type="text"
        readOnly
        className="input static-input"
        disabled
        value={value}
      />
    </FormItem>
  );
}

export default StaticTextFormItem;
