import React, { useState } from "react";
import * as yup from "yup";
import { Form, Formik } from "formik";
import {
  FormStatusError,
  SubmitButton,
  TextFormItem,
} from "~/components/forms/index";
import { SchemaFieldValues } from "~/utils/SchemaFieldValues";
import { useApiMutation } from "~/hooks/apiContext";
import useFormikStatusSubmit from "~/hooks/useFormikStatusSubmit";
import CheckboxFormItem from "~/components/forms/CheckboxFormItem";
import {
  isAccountInactiveError,
  isEmailAddressExistsError,
} from "~/backendApi/index";
import "./SignUpEmailForm.css";

const signUpValidationSchema = yup
  .object({
    emailAddress: yup.string().email().required(),
    password: yup.string().required(),
    confirmPassword: yup.string().when("password", {
      is: (val: string) => !!(val && val.length > 0),
      then: yup
        .string()
        .required()
        .oneOf([yup.ref("password")], "Password must match"),
    }),
    agreeToTerms: yup.bool().oneOf([true], "Required").required(),
  })
  .required();

type SignUpFormValues = SchemaFieldValues<typeof signUpValidationSchema>;

const signUpInitialValues: SignUpFormValues = {
  emailAddress: "",
  password: "",
  confirmPassword: "",
  agreeToTerms: false,
};

type SubmittedInfo = {
  readonly emailAddress: string;
  readonly verificationCode: string;
};

type SignUpEmailFormProps = {
  readonly className?: string;
  readonly type: "teacher" | "parent";
  readonly classWalletOrderId?: string;
  readonly initialValues?: Partial<SignUpFormValues>;
};

function SignUpEmailForm({
  className,
  type,
  classWalletOrderId,
  initialValues,
}: SignUpEmailFormProps) {
  const [submittedInfo, setSubmittedInfo] = useState<SubmittedInfo | undefined>(
    undefined,
  );

  const { mutateAsync: signUp } = useApiMutation(
    (client, { emailAddress, password }: SignUpFormValues) =>
      client.signUp(emailAddress, password, type, classWalletOrderId),
  );
  const { mutateAsync: resendOtpLink } = useApiMutation(
    (client, emailAddress: string) =>
      client.resendOtpLink({
        emailAddress,
      }),
  );
  const onEmailSubmit = useFormikStatusSubmit(
    async (values: SignUpFormValues) => {
      try {
        const tempSignUpCode = await signUp(values);
        setSubmittedInfo({
          emailAddress: values.emailAddress,
          verificationCode: tempSignUpCode,
        });
      } catch (e) {
        if (isEmailAddressExistsError(e)) {
          throw new Error("User with email address already exists");
        }

        if (isAccountInactiveError(e)) {
          const verificationCode = await resendOtpLink(values.emailAddress);
          setSubmittedInfo({
            emailAddress: values.emailAddress,
            verificationCode,
          });
          return;
        }

        throw e;
      }
    },
  );

  return (
    <div className={className}>
      {submittedInfo ? (
        <div>
          <h5>Verify email</h5>
          <p>
            We&apos;ve sent a verification link to your email address{" "}
            {submittedInfo.emailAddress}. Please check your email and follow the
            link.
          </p>
        </div>
      ) : (
        <Formik
          onSubmit={onEmailSubmit}
          initialValues={{
            ...signUpInitialValues,
            ...initialValues,
          }}
          validationSchema={signUpValidationSchema}
        >
          <Form>
            {type === "teacher" && <h4>Or Sign up with Email</h4>}
            <TextFormItem name="emailAddress" label="Email address" autoFocus />
            <TextFormItem
              type="password"
              name="password"
              label="Password"
              autoComplete="off"
            />
            <TextFormItem
              type="password"
              name="confirmPassword"
              label="Confirm password"
              autoComplete="off"
            />
            <CheckboxFormItem
              name="agreeToTerms"
              label={
                <>
                  I have read the{" "}
                  <a
                    href="/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferer"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="/terms-of-use/"
                    target="_blank"
                    rel="noopener noreferer"
                  >
                    Terms of Use
                  </a>
                  .
                </>
              }
            />
            <FormStatusError />
            <SubmitButton label="Sign Up" />
          </Form>
        </Formik>
      )}
    </div>
  );
}

export default SignUpEmailForm;
